import React from "react"
import CopyToClipboard from "./articleCopyToClipboard";


const ShareButtons = ({ article }) => {

    return (
        <div className="share-buttons-wrapper on-article-page" >
            <div className={"share-links"}>
                <CopyToClipboard article={article} />
                <a className={"facebook-sharing-link"} href={"https://www.facebook.com/sharer/sharer.php?u=https://rockaholik.netlify.app/blog/" + article.blog_category.slug + "/" + article.slug} target="_blank">
                    <i className="icon-facebook"/>
                </a>
                <a className={"twitter-sharing-link"} href={"https://twitter.com/intent/tweet?url=https://rockaholik.netlify.app/blog/" + article.blog_category.slug + "/" + article.slug + "&text=" + article.title + " - " + article.content + "&via="} target="_blank">
                    <i className="icon-twitter"/>
                </a>
                <a className={"pinterest-sharing-link"} href={"https://pinterest.com/pin/create/button/?url=https://rockaholik.netlify.app/blog/" + article.blog_category.slug + "/" + article.slug + "/&media=https://rockaholik.netlify.app/" + article.image.publicURL + "&description=" + article.title + " | Rockaholik.com" + article.content + ""}  target="_blank">
                    <i className="icon-pinterest"/>
                </a>
                <a className={"whatsApp-sharing-link"} href={"whatsapp://send?text=https://rockaholik.netlify.app/blog/" + article.blog_category.slug + "/" + article.slug}  target="_blank">
                    <i className="icon-whatsapp"/>
                </a>
                <a className={"sendEmail-link"} href={"mailto: ?&subject=" + article.title + " - Rockaholik.com " + "&cc=&bcc=&body=https://rockaholik.netlify.app/blog/" + article.blog_category.slug + "/" + article.slug + "/%20"} target="_blank">
                    <i className="icon-sendEmail"/>
                </a>
            </div>
        </div>
    )
};

export default ShareButtons
