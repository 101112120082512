import React, {useEffect, useRef} from "react"
import {graphql} from "gatsby"

import Index from "../components/layout"
import Img from "gatsby-image";
import ReactMarkdown from "react-markdown";
import Tooltip from "../components/paginatorTooltip";
import ShareButtons from "../components/articleSocialMediaSharing";
import TransitionLink from "gatsby-plugin-transition-link";
import scrollTo from "gatsby-plugin-smoothscroll";
import {gsap, Power2, TweenLite} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const query = graphql`
  query Article($slug: String!) {
    strapiArticle(slug: { eq: $slug }) {
      title
      slug
      content
      published_at
      author
      image {
        childImageSharp {
            fixed (width: 400 quality:80) {
                srcWebp
            }
            fluid(maxWidth: 1170, quality: 60) {
                ...GatsbyImageSharpFluid
            }
        }
        publicURL
      }
      blog_category {
       name
       slug
       icon {
         publicURL
       }
      }
      blog_labels {
        name
        slug
      }
    }
  }
`

const Project = ({ data, pageContext }) => {
    const article = data.strapiArticle;
    const blog ='blog';
    const {prev, next} = pageContext;

    let articleMainImage = useRef(null);
    let articleTitle = useRef(null);
    let articleCategories = useRef(null);
    let articleContent = useRef(null);

    useEffect(() => {
        gsap.from(articleMainImage, 0.8, {
            opacity: 0,
            scale: 1.6,
            ease: "Power2.easeInOut",
            delay: -0.2
        });
        gsap.from(articleTitle, 0.4, {
            opacity: 0,
            y:32,
            delay:0.1
        });
        gsap.from(articleCategories, 0.4, {
            opacity: 0,
            y:32,
            delay:0.1
        });
        gsap.from(articleContent, 0.4, {
            opacity: 0,
            y: 64,
            delay:0.1
        });
    }, [articleMainImage, articleTitle, articleContent, articleCategories]);


    const TRANSITION_LENGTH = .5

    const exitTransition = {
        length: TRANSITION_LENGTH,
        trigger: () =>  animateExitPage(),
    }

    const exitReverseTransition = {
        length: TRANSITION_LENGTH,
        trigger: () =>  animateExitPageReverse(),
    }

    const entryTransition = {
        delay: TRANSITION_LENGTH,
        trigger: () => animateEntryPage(),
    }

    function animateExitPage() {

        let html = document.querySelector("html");
        let mainLayout = document.querySelector('.main');
        let footer = document.querySelector('.footer');

        html.classList.add("smoothScrollOff")
        mainLayout.classList.add("invisible")

        TweenLite.to(
            mainLayout,
            .8,
            {
                opacity: 0,
                x: '60%',
                ease: Power2.easeInOut,
            }
        );

        TweenLite.to(
            footer,
            .4,
            {
                opacity: 0,
                ease: Power2.easeInOut,
            }
        );

        setTimeout(() => {
            scrollTo('#home')
        }, 450);
    }

    function animateExitPageReverse() {

        let html = document.querySelector("html");
        let mainLayout = document.querySelector('.main');
        let footer = document.querySelector('.footer');

        html.classList.add("smoothScrollOff")
        mainLayout.classList.add("invisible")

        TweenLite.to(
            mainLayout,
            .8,
            {
                opacity: 0,
                x: '-60%',
                ease: Power2.easeInOut,
            }
        );

        TweenLite.to(
            footer,
            .4,
            {
                opacity: 0,
                ease: Power2.easeInOut,
            }
        );

        setTimeout(() => {
            scrollTo('#home')
        }, 450);
    }

    function animateEntryPage() {

        let html = document.querySelector("html");

        setTimeout(() => {
            html.classList.remove("smoothScrollOff")
        }, 120);
    }

    return (
        <Index
            title={article.title}
            description={article.content}
            mainImage={article.image.childImageSharp.fixed.srcWebp}
            blogPage={blog}
        >

        <section className="regular-section blog-header-wrapper">
            <div className="blog-header">
                <div className="blog-header-image">
                    <div ref={el => (articleMainImage = el)} className="image-wrapper" >
                        <Img fluid={article.image.childImageSharp.fluid} alt={article.title} />
                    </div>
                </div>
                <h2 ref={el => (articleTitle = el)}>{article.title}</h2>
            </div>
            <div ref={el => (articleCategories = el)} className="blog-categories">
                <div className="blog-category">
                    <img src={article.blog_category.icon.publicURL} alt="{article.node.blog_category.name}"/>
                    <TransitionLink
                        to={`/blog/${article.blog_category.slug}`}
                        exit={exitTransition}
                        entry={entryTransition}
                    >
                        {article.blog_category.name}
                    </TransitionLink>
                </div>
                <div>
                    <ShareButtons article={article}/>
                </div>
            </div>
        </section>

        <section ref={el => (articleContent = el)} className="blog-section blog-content">
            <ReactMarkdown source={article.content} />
            <div className="blog-labels bottom-labels">
                <p>
                    {article.blog_labels.map((label) =>{
                        return <TransitionLink
                            to={`/blog/${label.slug}`}
                            exit={exitTransition}
                            entry={entryTransition}
                        >
                            <span>#{label.name}    </span>
                        </TransitionLink>
                    })}
                </p>
            </div>
            <div className="blog-bottom">
                <span className="published-wrapper">Published:<span className="published">{article.published_at}</span></span>
                <span className="author-wrapper">Author:<span className="author">{article.author}</span> </span>
            </div>
            <ShareButtons article={article}/>
        </section>

            <section className="regular-section more-collections">
                <h2>More Articles</h2>
                <div className="paginator-big">

                    {prev &&
                    <Tooltip text={prev.title}>
                        <TransitionLink
                            className="read-more"
                            to={"/blog/" + prev.blog_category.slug + "/" + prev.slug}
                            exit={exitTransition}
                            entry={entryTransition}
                        >
                            <i className="icons icon-west"/>
                            <span className="collection-number">Prev Collection</span>
                        </TransitionLink>
                    </Tooltip>
                    }

                    {next &&
                    <Tooltip text={next.title}>
                        <TransitionLink
                            className="read-more"
                            to={"/blog/" + next.blog_category.slug + "/" + next.slug}
                            exit={exitReverseTransition}
                            entry={entryTransition}
                        >
                            <span className="collection-number">Next Collection</span>
                            <i className="icons icon-east"/>
                        </TransitionLink>
                    </Tooltip>
                    }
                </div>
            </section>

        </Index>
    )
}

export default Project
